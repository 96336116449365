import React, { useEffect, useState } from "react"
import SEO from "./../components/seo"
import Layout from "../components/layout"
import { Link, navigate } from "gatsby"
import { inscription } from "./../actions/auth"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons"
import { graphql, useStaticQuery } from "gatsby"
import MaskInput from 'react-maskinput'
import { setZone, setPickupPoint } from "../actions/panier"

export const Inscription = props => {
  const dataGroup = useStaticQuery(graphql`
    query dataInscription {
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_address
            group_ville
            group_horaire
            group_person
            group_zone_id
          }
        }
      }
    }
  `)

  const dispatch = useDispatch()
  const identifiants = useSelector(s => s.identifiants)
  const loading = useSelector(state => state.loadingR)
  const panier = useSelector(s => s.panierR)
  //const [listGroup, setListGroup]   = useState()

  const [form, setForm] = useState({
    lastname: "",
    firstname: "",
    email: "",
    password: "",
    id_gender: "1",
    reponse: null,
    identifiant: "",
    adresse: "",
    codePostal: "",
    ville: "",
    telephone: "",
    group: panier.pickupPoint,
    zone_id: panier.zone,
  })

  const [quest, setQuest] = useState(null)

  useEffect(() => {
    if (props.pageContext.group_id && props.pageContext.zone_id) { // lien d'inscription avec groupe pré-sélectionné
      console.log('Mise à jour groupe: '+props.pageContext.group_id+ ' zone:'+props.pageContext.zone_id);
      dispatch(setPickupPoint(props.pageContext.group_id));
      dispatch(setZone(props.pageContext.zone_id));
      setForm({ ...form, ['group']: props.pageContext.group_id })
      setForm({ ...form, ['zone_id']: props.pageContext.zone_id })
    }
  }, [dispatch, props.pageContext.zone_id, props.pageContext.group_id]);

  useEffect(() => {
    //setListGroup(dataGroup.allMysqlGroupe.edges.filter(legroupe => parseInt(legroupe.node.group_zone_id) === parseInt(panier.zone) && parseInt(legroupe.node.group_id) === parseInt(panier.pickupPoint)))
    const generateRandomQuestion = () => {
      const types = [
        { type: "nb", quest: "Combien de lettres y-a-t'il dans le mot «{xxx}» ?" },
        { type: "pos", quest: "Quelle est la {x} lettre du mot «{xxx}» ?" },
      ]

      const words = ["manger", "fruit", "bio", "fraise"]
      const type = types[rnd(0, types.length - 1)]
      const word = words[rnd(0, words.length - 1)]
      const letterPos = rnd(0, word.length - 1)
      const fr = ["première", "deuxième", "troisième", "quatrième", "cinquième", "sixème", "septième", "huitième", "neuvième", "dixième", "onzième"]

      switch (type.type) {
        case "nb":
          return { question: type.quest.replace("{xxx}", word), reponse: word.length }
        case "pos":
          return { question: type.quest.replace("{xxx}", word).replace("{x}", fr[letterPos]), reponse: word[letterPos] }
        default:
      }
    }
    const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
    const quest = generateRandomQuestion()
    setQuest(quest)
  }, [dataGroup.allMysqlGroupe.edges, panier])


  const handleUpdate = event => {
    if (event.target.name === "firstname" || event.target.name === "lastname") {
      setForm({ ...form, [event.target.name]: event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ]/g, "") })
    } else {
      setForm({ ...form, [event.target.name]: event.target.value })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (loading.loading) return;

    if (form.reponse.toLowerCase() + '' === quest.reponse + '') {
      dispatch(inscription(form, panier))
    } else {
      alert("La réponse à la question n'est pas bonne")
    }
  }

  if (identifiants.identifiants != null) {
    if (typeof window !== "undefined") {
      if (panier.products.length >= 1) {
        navigate(`/apps/mon-compte/`)
      } else {
        navigate(`/`)
      }
    }
  }
  return (
    <>
      <Layout>
        <SEO title="Inscription" description="Créer un compte Agrobioconso pour commander des paniers de produits locaux et bio" />
        <div className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-2 has-text-centered dreamland">Creation de compte Agrobiocono</h1>
            </div>
          </div>
        </div>
        <div className="container is-fluid pb-5 pattern-cross-dots">
          <div className="columns is-centered login">
            <div className="column couleurShadow" style={{ maxWidth: 1200 }}>
              <p className="has-text-right" style={{ fontStyle: "italic" }}>
                Vous avez déjà un compte&nbsp;?&nbsp;
                <Link to="/connexion">Connectez-vous&nbsp;!</Link>
              </p>

              <div className="box">
                <form method="post" onSubmit={event => handleSubmit(event)}>
                  <h2 className="is-size-4 mb-4" style={{ color: "#7e421e", paddingBottom: 5, borderBottom: "1px solid #ccc" }}>Identité</h2>
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label" htmlFor="identifiant">
                          Identifiant&nbsp;:
                        </label>
                        <div className="control">
                          <input type="text" id="identifiant" name="identifiant" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre identifiant" />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor="password">
                          Mot de passe&nbsp;:
                        </label>
                        <div className="control">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={e => handleUpdate(e)}
                            required
                            className="input"
                            placeholder="Votre mot de passe"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label" htmlFor="lastname">
                          Nom&nbsp;:
                        </label>
                        <div className="control">
                          <input type="text" id="lastname" name="lastname" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre nom" />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor="firstname">
                          Prénom&nbsp;:
                        </label>
                        <div className="control">
                          <input type="text" id="firstname" name="firstname" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre prénom" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="is-size-4 mb-4" style={{ color: "#7e421e", paddingBottom: 5, borderBottom: "1px solid #ccc" }}>Coordonnées</h2>
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label" htmlFor="adresse">
                          Rue&nbsp;:
                        </label>
                        <div className="control">
                          <input type="text" id="adresse" name="adresse" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre adresse" />
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column is-4">
                          <div className="field">
                            <label className="label" htmlFor="codePostal">
                              Code postal &nbsp;:
                            </label>
                            <div className="control">
                              <MaskInput
                                id="codePostal" name="codePostal" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre code postal"
                                alwaysShowMask mask={'00000'}
                                size={5}
                                showMask maskChar="_"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="field">
                            <label className="label" htmlFor="ville">
                              Ville &nbsp;:
                            </label>
                            <div className="control">
                              <input type="text" id="ville" name="ville" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre ville" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label" htmlFor="email">
                          Adresse email&nbsp;:
                        </label>
                        <div className="control">
                          <input type="email" id="email" name="email" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre adresse email" />
                        </div>
                        {/* {loading.status === false && (
                          <div className="notification is-danger" style={{ marginTop: "1rem" }}>
                            L&apos;adresse email est déjà utilisée, veuillez en choisir une autre ou <Link to={`/connexion`}>vous connecter</Link>
                          </div>
                        )} */}
                      </div>

                      <div className="field">
                        <label className="label" htmlFor="telephone">
                          Téléphone&nbsp;:
                        </label>
                        <div className="control">
                          <MaskInput
                            id="telephone"
                            name="telephone"
                            onChange={e => handleUpdate(e)}
                            className="input"
                            placeholder="Votre téléphone"
                            alwaysShowMask mask={'00 00 00 00 00'}
                            size={10}
                            showMask maskChar="_"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                  <h2 className="is-size-4 mb-4" style={{ color: "#7e421e", paddingBottom: 5, borderBottom: "1px solid #ccc" }}>Validation</h2>
                  <div className="columns is-vcentered">
                    <div className="column">
                      <div>Comme membre, je m'engage&nbsp;:</div>
                      <ul>
                        <li>à payer mes commandes à la livraison</li>
                        <li>à prendre possession de mes produits dans le délai de livraison prévu. A partir de ce moment ils sont réputés être sous ma propre responsabilité, notamment en ce qui concerne les risques de rupture de la chaîne du froid.</li>
                      </ul>
                      <div className="field mt-4">
                        <label className="label" htmlFor="reponse">
                          {quest !== null && quest.question}
                        </label>
                        <div className="control">
                          <input className="input" name="reponse" id="reponse" type="text" required onChange={e => handleUpdate(e)} style={{ width: "20%" }} />
                        </div>
                      </div>
                    </div>
                    <div className="column is-4 has-text-centered">
                      <button type="submit" className={`button is-primary is-large dreamland ${loading.loading ? `is-loading` : ``}`} style={{ fontSize: "2rem", background: "#4d9f16", whiteSpace: "normal", lineHeight: "2rem", height: "auto" }}>
                        <FontAwesomeIcon icon={faCheck} className="mr-2" /> J'accepte les conditions d'engagement et je m'inscris
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}


export default Inscription
